<!--BaseLayout.vue-->
<template>
  <div class="min-h-screen flex flex-col">
    <header class="has-primary-background-color text-white p-4">
      <nav class="container mx-auto flex justify-between items-center">
        <router-link to="/" class="text-xl font-bold">
        <a href="#" class="text-xl font-bold"><img src="@/assets/logo.png" alt="Logo" class="h-20"></a>
        </router-link>
        <div>
          <router-link to="/programmation" class="text-gray-300 mx-2">Programmation</router-link>
          <router-link to="/tableaux" class="text-gray-300 mx-2">Tableaux</router-link>
        </div>
      </nav>

    </header>
    <div class="has-primary-background-color p-3 text-white text-center border-t-2 border-white">
      <h1 class="text-2xl">{{ pageTitle }}</h1>
    </div>

    <main class="flex-grow container mx-auto p-4">
      <div class="mt-6">
        <p class="text-sm text-gray-500 text-center">
          <em>Les horaires des matchs sont susceptibles d'être modifiés. Seules les convocations envoyées par SMS ou indiquées par les permanents font foi.</em>
        </p>
      </div>
      <router-view />
    </main>

    <footer class="bg-gray-800 text-white p-4">
      <div class="container mx-auto text-center">
        &copy; 2024 HasApp
      </div>

    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const pageTitle = ref(document.title);

onMounted(() => {
  const updateTitle = () => {
    pageTitle.value = document.title;
  };

  const observer = new MutationObserver(updateTitle);
  observer.observe(document.querySelector('title'), { childList: true });

  // Mettre à jour le titre immédiatement au montage
  updateTitle();

  // Clean up the observer when the component is unmounted
  return () => {
    observer.disconnect();
  };
});
</script>

<style>
/* Ajoutez vos styles personnalisés ici */
.has-primary-background-color {
  background-color: #1a3151;
}
</style>
