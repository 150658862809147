<!--DatesList.vue-->
<template>
  <div class="flex flex-wrap gap-2">
    <button
        v-for="date in dates"
        :key="date"
        @click="selectDate(date)"
        :class="[
        'w-32 h-12 rounded-lg flex items-center justify-center',
        isSelected(date) ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white hover:bg-blue-700'
      ]"
    >
      {{ formatDate(date) }}
    </button>
  </div>
</template>

<script setup>
import {defineProps, defineEmits} from 'vue';
import {format} from 'date-fns';
import {fr} from 'date-fns/locale';

// Définir les props
const props = defineProps({
  dates: {
    type: Array,
    required: true
  },
  selectedDate: {
    type: String,
    required: true
  }
});
const emit = defineEmits(['date-selected']);

// Fonction pour formater la date en français
const formatDate = (date) => {
  const formattedDate = format(date, 'EEEE dd MMMM', { locale: fr });
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

// Émettre un événement lorsque la date est sélectionnée
const selectDate = (date) => {
  const formattedDate = format(date, 'yyyy-MM-dd');
  emit('date-selected', formattedDate);
};

// Fonction pour vérifier si une date est sélectionnée
const isSelected = (date) => {
  return props.selectedDate && props.selectedDate === format(date, 'yyyy-MM-dd');
};
</script>

<style scoped>
/* Ajoutez des styles personnalisés ici si nécessaire */
</style>
