<template>
  <div class="flex flex-row items-center">
  <div
        v-for="(setScore, index) in scores"
        :key="index"
        :class="{
        'has-primary-background-color text-white': isWinner(setScore, pos),
        'bg-blue-100': !isWinner(setScore, pos)
      }"
        class="p-1 w-8 m-1 flex items-center  justify-center "
    >
      {{ setScore[pos] }}
    <sup v-if="setScore.length > 2 && !isWinner(setScore, pos)" class="ml-1 w-2 text-[8px]">{{ setScore[2] }}</sup>
    <sup v-else-if="setScore.length > 2 && isWinner(setScore, pos)" class="ml-1 w-2 text-[8px]">{{ getTieBreakDisplayValue(setScore[2]) }}</sup>

  </div>
  </div>
</template>

<script setup>
import {defineProps, toRefs} from 'vue';

const props = defineProps({
  scores: {
    type: Array,
    required: true,
  },
  pos: {
    type: Number,
    required: true,
  },
});

const { scores,pos } = toRefs(props);

function isWinner(setScore, pos) {
  return setScore[pos] > setScore[(pos + 1) % 2];
}

function getTieBreakDisplayValue(tieBreakScore) {
  const calculatedValue = tieBreakScore + 2;
  return calculatedValue < 7 ? 7 : calculatedValue;
}
</script>

<style scoped>
/* Custom styles here */
</style>
