<template>
  <div class="meet-element p-4 bg-white rounded shadow border border-gray-400" :data-pos="meet.meetpos">

    <template  v-if="meetType === 'single'" >
    <ul class="list-disc list-inside border-b last:border-none ">

      <PlayerItemElement
          v-for="index in [0, 1]"
          :key="index"
          :player="playersByPosition[index]?.player || { firstname: meet.previousMeets[index] || 'Qualifié', lastname: '' }"
          :ranking="playersByPosition[index]?.ranking || { simpleRank: '' }"
          :club="playersByPosition[index]?.club || { name: '' }"
          :meetType="meetType"
          :score="meet.score"
          :pos="index"
          :winner="meet.meetwinnerpos === index ? 'winner' : 'looser'"
      />
      <li class="text-sm text-gray-500 mt-2 flex justify-between border-top-dashed">
        <p v-if="meet.drawInfos">{{ meet.drawInfos.name }} </p>
        <p>{{ formattedDate }}</p>
      </li>

    </ul>
    </template>
    <template v-else-if="meetType === 'double'">
      <TeamItemElement
          v-for="(team, index) in getTeams(meet.meetPlayers)"
          :key="index"
          :teamId="index + 1"
          :players="team"
          :meetType="meetType"
      />
    </template>

  </div>
</template>

<script setup>
import {computed, defineProps, toRefs} from 'vue';
import PlayerItemElement from "@/components/PlayerItemElement.vue";
import TeamItemElement from "@/components/TeamItemElement.vue";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const props = defineProps({
  meet: {
    type: Object,
    required: true
  },
  meetType: {
    type: String,
    required: true
  }
});

const { meet } = toRefs(props);

function getTeams(players) {
  const teams = [];
  for (let i = 0; i < players.length; i += 2) {
    teams.push([players[i], players[i + 1]]);
  }
  return teams;
}
const playersByPosition = computed(() => {
  const positions = {};

  meet.value.meetPlayers.forEach(meetplayer => {
    positions[meetplayer.playerPos] = meetplayer;
  });
  return positions;
});

const formattedDate = computed(() => {
  if (!meet.value.playedAt) {
    return 'Non programmé';
  }
  const formatted = format(new Date(meet.value.playedAt), "EEEE d MMMM yyyy 'à' HH:mm", { locale: fr });
  return ucFirst(formatted);});
function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
</script>

<style scoped>
.border-top-dashed {
  border: none;
  border-top-style: dashed;
  border-top-width: 1px;
  border-top-color: #353639;
  margin-top: 1rem;
  padding-top: 0.5rem;
}
</style>
