<template>
  <li class="flex items-center justify-between space-x-4 p-0" :class="{ 'border-b last:border-none': meetType === 'single' }">
    <div class="flex flex-col">
      <span class="text-lg font-medium">
      {{ player.lastname }} {{ player.firstname }}
        <span v-if="ranking.simpleRank"> ({{ ranking.simpleRank }})</span>
      </span>
      <span class="text-sm text-gray-500">
        {{ club.name }}
      </span>
    </div>
    <div class="flex flex-row items-center">
      <div v-if="winner === 'winner'" class="bg-green-500 text-white p-1 px-2 m-1">V</div>
      <ScoreDisplayItem :scores="score" :pos="pos" />
    </div>
  </li>
</template>

<script setup>
import {defineProps, toRefs} from 'vue';
import ScoreDisplayItem from '@/components/ScoreDisplayItem.vue';

const props = defineProps({
  player: {
    type: Object,
    required: true,
  },
  ranking: {
    type: Object,
    required: true,
  },
  club: {
    type: Object,
    required: true,
  },
  meetType: {
    type: String,
    required: true,
  },
  score: {
    type: Array,
    required: false,
    default: () => [],
  },
  pos: {
    type: Number,
    required: true,
  },
  winner: {
    type: String,
    required: false,
    default: 'looser',
  },
});
const { player,ranking,club,meetType,score,pos } = toRefs(props);
</script>

<style scoped>
/* Custom styles here */
</style>
