<template>

  <div v-if="pdfUrl" class="w-full flex justify-center mt-6">
    <a :href="pdfUrl" target="_blank" download class="inline-flex items-center justify-center px-4 py-2 bg-blue-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-blue-700 focus:bg-blue-700 active:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition ease-in-out duration-150 w-full max-w-xs">
      <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v9.586l3.293-3.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L9 12.586V3a1 1 0 011-1z" clip-rule="evenodd" />
        <path fill-rule="evenodd" d="M4 16a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1z" clip-rule="evenodd" />
      </svg>
      Télécharger le programme
      <svg class="w-4 h-4 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v9.586l3.293-3.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L9 12.586V3a1 1 0 011-1z" clip-rule="evenodd" />
        <path fill-rule="evenodd" d="M4 16a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1z" clip-rule="evenodd" />
      </svg>
    </a>
  </div>


  <div v-if="meets.length" class="meet-list mt-4">
    <ul>
      <!-- Render meet details here -->
      <li v-for="(meet, index) in meets" :key="index" class="p-4 bg-gray-100 rounded hover:bg-gray-200">
        <MeetElement :meet="meet" :meetType="'single'" />
      </li>
    </ul>
  </div>
  <div v-else class="no-meets p-4 bg-gray-100 rounded hover:bg-gray-200 text-center mt-4">Aucune rencontre pour cette date</div>
</template>

<script setup>
import {defineProps, ref, watch } from 'vue';
import ApiService from "@/Services/ApiService";
import MeetElement from "@/components/MeetElement.vue";

const props = defineProps({
  selectedDate: {
    type: String,
    required: true
  },
  dates: {
    type: Array,
    required: true
  }
});

const meets = ref([]);
const pdfUrl = ref(null);

const fetchMeets = async (date) => {
  try {
    const response = await ApiService.get(`/meet/meetByDate/${date}`);
    meets.value = response.data;

    const responsepdfUrl = await ApiService.get(`/meet/progByDate/${date}`);
    pdfUrl.value = responsepdfUrl.data.url;

  } catch (error) {
    console.error('Failed to fetch meets:', error);
    meets.value = [];
  }
};

watch(() => props.selectedDate, (newDate) => {
  if (newDate) {
    fetchMeets(newDate);
  }
}, { immediate: true });
</script>

<style scoped>
.meet-list {
  /* Styles pour la liste des rencontres */
}

.meet-item {
  /* Styles pour chaque rencontre */
}

.no-meets {
  /* Styles pour l'état sans rencontre */
}
</style>
