import { createRouter, createWebHistory } from 'vue-router';

import HomePage from "@/views/HomePage.vue";
import RencontresPage from "@/views/RencontresPage.vue";
import ProgrammePage from "@/views/ProgrammePage.vue";
import TableauxPage from "@/views/TableauxPage.vue";

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
        meta: { title: 'Open d\'été d\'urville-Nacqueville',titlePage:'' }
    },
    {
        path: '/rencontres',
        name: 'RencontresPage',
        component: RencontresPage,
    },
    {
        path: '/programmation',
        name: 'ProgrammePage',
        component: ProgrammePage,
        meta: { title: 'Programmation',titlePage : 'Programmation'},

    },
    {
        path: '/programmation/:date',
        name: 'ProgrammationDate',
        component: ProgrammePage,
        /*meta: { title: 'Programmation du jour',titlePage : 'Programmation du jour'},*/
        props: route => ({ selectedDate: new Date(route.params.date) })
    },
    {
        path: '/tableaux',
        name: 'Tableaux',
        component: TableauxPage,
        meta: { title: 'Tableaux',titlePage : 'Tableaux' }

    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {


    document.title = 'Open d\'été d\'urville-Nacqueville - '+to.meta.titlePage || 'Open d\'été d\'urville-Nacqueville';
    next();
});
export default router;
