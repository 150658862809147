<template>
  <div>
    <h1 class="text-2xl font-bold">Recontres Page</h1>
    <p class="mt-4">Welcome to the home page!</p>
  </div>
</template>

<script setup>

</script>

<style scoped>
/* Ajoutez vos styles personnalisés ici */
</style>
