<template>
  <div class="max-w-3xl mx-auto p-8 bg-white shadow-lg rounded-lg">
    <p class="text-2xl font-bold text-center text-gray-800 mb-6">Du 15 juin au 30 juin 2024</p>


    <hr class="border-gray-300 my-8">
    <div class="text-gray-700 leading-relaxed space-y-6 text-center mb-10">

      <ul class="list-none pl-5 mt-2 space-y-1">
        <li>Les finales auront lieu le dimanche 30 juin</li>
        <li>Consolante Hommes et double messieurs à 15h30</li>
        <li>Hommes et Femmes à 17h00</li>
        <li><strong>À l'issue des rencontres, remise des prix et pot de l'amitié.</strong></li>
      </ul>

    </div>

    <hr class="border-gray-300 my-8">

    <div class="flex justify-center mb-8">
      <img src="@/assets/open-ete-2024.jpg" alt="Logo" class="w-full max-w-3xl rounded-lg shadow-md"/>
    </div>

    <div class="text-gray-700 leading-relaxed space-y-6 text-center">
      <div>
        <h2 class="text-xl font-semibold">Tableaux</h2>
        <ul class="list-none pl-5 mt-2 space-y-1">
          <li><strong>Simples DAMES et MESSIEURS</strong></li>
          <li><strong>Doubles DAMES et MESSIEURS</strong></li>
          <li><strong>Doubles MIXTES</strong></li>
        </ul>
        <p class="mt-4">Consolante jusqu'à 15/5.</p>
      </div>

      <div>
        <h2 class="text-xl font-semibold">Droits d'engagements</h2>
        <ul class="list-none pl-5 mt-2 space-y-1">
          <li>Adultes : 15€</li>
          <li>Jeunes : 10€</li>
          <li>Doubles : 10€ par équipe</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Ajoutez vos styles personnalisés ici si nécessaire */
</style>
<script setup lang="ts">
</script>