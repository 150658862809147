<template>
  <div>
     <ul class="list-disc list-inside flex  border-b">
      <PlayerItemElement
          v-for="(player, index) in players"
          :key="index"
          :player="player.player"
          :ranking="player.ranking"
          :club="player.club"
          :meetType="meetType"
      />
    </ul>
  </div>
</template>

<script>

import PlayerItemElement from "@/components/PlayerItemElement.vue";

export default {
  components: {
    PlayerItemElement,

  },
  props: {
    teamId: {
      type: Number,
      required: true,
    },
    players: {
      type: Array,
      required: true,
    },
    meetType: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Custom styles here */
</style>
