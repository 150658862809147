<template>
  <div class="bg-white shadow rounded-lg p-1">
    <Suspense>
      <template #default>
        <DrawList />
      </template>
      <template #fallback>
        <div class="flex justify-center items-center h-32">
          <div class="loader"></div>
        </div>
      </template>
    </Suspense>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue';

// Définir le composant asynchrone pour CompetitionList
const DrawList = defineAsyncComponent(() =>
    import('../components/DrawList.vue')
);
</script>

<style scoped>
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
