<!--ProgrammePage.vue-->
<template>
  <div class="bg-white shadow rounded-lg p-1">
    <DatesList @date-selected="handleDateSelected" :selectedDate="selectedDate" :dates="dates" />
    <MeetByDayList :selectedDate="selectedDate" :dates="dates" />
  </div>
</template>

<script setup>
import {ref, onMounted,defineProps,watch} from 'vue';
import { useRouter } from 'vue-router';
import {format} from 'date-fns';
import { fr } from 'date-fns/locale';
import DatesList from '@/components/DatesList.vue';
import MeetByDayList from '@/components/MeetByDayList.vue';

const props = defineProps({
  selectedDate: {
    type: Date,
    required: false,
    default: null
  }
});

const router = useRouter();


// Générer la liste des dates du 16 juin 2024 au 30 juin 2024
const startDate = new Date(2024, 5, 16); // Mois indexé à 0, donc 5 = juin
const endDate = new Date(2024, 5, 30);

const dates = ref([]);
let currentDate = startDate;

while (currentDate <= endDate) {
  dates.value.push(new Date(currentDate));
  currentDate.setDate(currentDate.getDate() + 1);
}

const selectedDate = ref('');

// Définir la date sélectionnée par défaut lors du montage du composant
onMounted(() => {
  if (props.selectedDate) {
    selectedDate.value = format(props.selectedDate, 'yyyy-MM-dd');
  } else if (dates.value.length > 0) {
    selectedDate.value = format(dates.value[0], 'yyyy-MM-dd');
  }
  updatePageTitle();
});
watch(() => props.selectedDate, (newDate) => {
  if (newDate) {
    selectedDate.value = format(newDate, 'yyyy-MM-dd');
  } else if (dates.value.length > 0) {
    selectedDate.value = format(dates.value[0], 'yyyy-MM-dd');
  }
  updatePageTitle();
});
const handleDateSelected = (date) => {
  selectedDate.value = date;
  router.push({ name: 'ProgrammationDate', params: { date: date } });
  updatePageTitle();
};
const updatePageTitle = () => {
  if (selectedDate.value) {
    const formattedDate = format(new Date(selectedDate.value), 'EEEE dd MMMM yyyy', {locale: fr});
    document.title = `Open d'été d'urville-Nacqueville - Programmation - ${formattedDate}`;
  } else {
    document.title = 'Open d\'été d\'urville-Nacqueville - Programmation';
  }
}


</script>

<style scoped>
/* Ajoutez vos styles personnalisés ici */
</style>
